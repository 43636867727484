<template>
  <div
    class="wrap__content"
    v-bind:class="{ 'wrap__content--placeholder': !user || !applications }"
  >
    <div class="page__placeholder" v-if="!user || !applications">
      <div class="form__block--placeholder">
        <div class="form__placeholder--circle">
          <div class="placeholder__circle">
            <svg
              width="73"
              height="73"
              viewBox="0 0 73 73"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="36.5"
                cy="36"
                r="24"
                stroke="#FFF7E1"
                stroke-width="2"
              />
              <g filter="url(#filter0_d_22_5658)">
                <path
                  d="M16.1604 23C13.5332 26.8866 12 31.5673 12 36.6045C12 50.0778 22.969 61 36.5 61C50.031 61 61 50.0778 61 36.6045C61 34.8685 60.8179 33.1748 60.4716 31.5413"
                  stroke="#FFCC47"
                  stroke-width="4"
                  stroke-linecap="round"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_22_5658"
                  x="5"
                  y="15.9998"
                  width="63"
                  height="52.0002"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset />
                  <feGaussianBlur stdDeviation="2.5" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 1 0 0 0 0 0.722826 0 0 0 0 0 0 0 0 0.5 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_22_5658"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_22_5658"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </div>
        </div>
        <div class="form__placeholder--logo">
          <img src="../../assets/img/logo.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="content__page--block" v-if="user && applications">
      <div class="content__page">
        <!-- content__page--margintop -->

        <div class="container">
          <div class="section__welcome--block">
            <div class="row">
              <div class="col-xl-12">
                <div class="section__welcome section__welcome--dashboard">
                  <div class="section__welcome--row">
                    <div class="section__welcome--left">
                      <div class="section__welcome--title">
                        Добро пожаловать
                      </div>
                      <div class="section__welcome--info">
                        <div class="welcome__info--left">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21"
                              stroke="#0E1839"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
                              stroke="#0E1839"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                        <div class="welcome__info--right">
                          <div class="welcome__info--name">
                            <span
                              >{{ user.data.surname }}
                              {{ user.data.name }}</span
                            >
                            <a
                              :href="'/' + $i18n.locale + '/account/edit'"
                              class="account--link"
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
                                  stroke="#FFCC47"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M18.5 2.49998C18.8978 2.10216 19.4374 1.87866 20 1.87866C20.5626 1.87866 21.1022 2.10216 21.5 2.49998C21.8978 2.89781 22.1213 3.43737 22.1213 3.99998C22.1213 4.56259 21.8978 5.10216 21.5 5.49998L12 15L8 16L9 12L18.5 2.49998Z"
                                  stroke="#FFCC47"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </a>
                          </div>
                          <div class="welcome__info--position">
                            {{ user.data.roles.name }}
                          </div>
                        </div>
                      </div>

                      <div class="administrator__create_account">
                        <a href="#" class="administrator__create_account--link">
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.5 1.5L16.5 4.5"
                              stroke="#6B69DF"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M5.625 15.375L14.25 6.75L11.25 3.75L2.625 12.375L1.5 16.5L5.625 15.375Z"
                              stroke="#6B69DF"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          Создать учетную запись
                        </a>
                      </div>
                    </div>
                    <div class="section__welcome--right">
                      <div class="section__welcome--info dashboard--welcome">
                        <div class="row">
                          <div class="col-lg-4">
                            <div class="administrator__dashboard--item">
                              <div class="administrator__dashboard--block">
                                <div class="administrator__dashboard--circle">
                                  <div
                                    class="administrator__dashboard--border"
                                  ></div>
                                  <div class="administrator__dashboard--number">
                                    157
                                  </div>
                                  <div class="administrator__dashboard--icon">
                                    <img
                                      src="../../assets/img/dashboard-received.svg"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="administrator__dashboard--text">
                                Заявлений поступило
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="administrator__dashboard--item">
                              <div class="administrator__dashboard--block">
                                <div class="administrator__dashboard--circle">
                                  <div
                                    class="administrator__dashboard--border"
                                  ></div>
                                  <div class="administrator__dashboard--number">
                                    91
                                  </div>
                                  <div class="administrator__dashboard--icon">
                                    <img
                                      src="../../assets/img/dashboard-approved.svg"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="administrator__dashboard--text">
                                Одобрено
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="administrator__dashboard--item">
                              <div class="administrator__dashboard--block">
                                <div class="administrator__dashboard--circle">
                                  <div
                                    class="administrator__dashboard--border"
                                  ></div>
                                  <div class="administrator__dashboard--number">
                                    66
                                  </div>
                                  <div class="administrator__dashboard--icon">
                                    <img
                                      src="../../assets/img/dashboard-rejected.svg"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="administrator__dashboard--text">
                                Отклонено
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="registry__table">
            <div class="registry__tabs">
              <div
                class="registry__tabs--item"
                v-bind:class="{ 'registry__tabs--active': Categories == 'all' }"
                @click="categoriesTab('all')"
              >
                <a href="#">Все заявления</a>
              </div>
              <div
                class="registry__tabs--item"
                v-bind:class="{
                  'registry__tabs--active': Categories == 'foreign',
                }"
                @click="categoriesTab('foreign')"
              >
                <a href="#">Иностранные туристы</a>
              </div>
              <div
                class="registry__tabs--item"
                v-bind:class="{
                  'registry__tabs--active': Categories == 'kids',
                }"
                @click="categoriesTab('kids')"
              >
                <a href="#">Несовершеннолетние пассажиры</a>
              </div>
            </div>

            <div class="table__block--block table__block--shadow">
              <div class="table__block--top">
                <div class="table__block--title">
                  Реестр поступивших заявлений
                </div>
              </div>

              <div class="table__block--content">
                <div class="table__block--filter">
                  <div class="table__filter--search">
                    <label class="search__label">
                      <input
                        type="text"
                        value=""
                        name="filter_search"
                        v-model="filters.search"
                        placeholder="Поиск по таблице"
                      />
                      <button>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                            stroke="#0E1839"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M21.0004 21L16.6504 16.65"
                            stroke="#0E1839"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                    </label>
                  </div>
                  <div class="table__filter--order">
                    <multiselect
                      v-model="filterCategories"
                      :options="orderList"
                      label="name"
                      :clearOnSelect="false"
                      :clear-on-select="false"
                      placeholder="Статус"
                      :hideSelected="true"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">{{
                        option.name
                      }}</template>
                    </multiselect>
                  </div>
                </div>
                <!-- :items="applications.data" -->
                <v-data-table
                  :headers="headers"
                  :items="filteredlist"
                  :loading="loading"
                  :options.sync="options"
                  :footer-props="{
                    'items-per-page-options': [5, 10, 20, 30, 40, 50],
                  }"
                >
                  <template slot="applications.data" slot-scope="props">
                    <td>{{ props.id }}</td>
                    <td>{{ props.name }}</td>
                    <td>{{ props.item.date }}</td>
                    <td>{{ props.item.time }}</td>
                    <td>{{ props.item.from }}</td>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <a
                      class="tb__table--btn"
                      v-if="item.category_id == 'foreign'"
                      :href="
                        '/' +
                        $i18n.locale +
                        '/administrator/tourist-views/' +
                        item.id
                      "
                    >
                      <svg
                        width="21"
                        height="21"
                        viewBox="0 0 21 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.875 10.5C0.875 10.5 4.375 3.5 10.5 3.5C16.625 3.5 20.125 10.5 20.125 10.5C20.125 10.5 16.625 17.5 10.5 17.5C4.375 17.5 0.875 10.5 0.875 10.5Z"
                          stroke="#FFCC47"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M10.5 13.125C11.9497 13.125 13.125 11.9497 13.125 10.5C13.125 9.05025 11.9497 7.875 10.5 7.875C9.05025 7.875 7.875 9.05025 7.875 10.5C7.875 11.9497 9.05025 13.125 10.5 13.125Z"
                          stroke="#FFCC47"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </a>
                    <a
                      class="tb__table--btn"
                      v-if="item.category_id == 'kids'"
                      :href="
                        '/' +
                        $i18n.locale +
                        '/administrator/kids-views/' +
                        item.id
                      "
                    >
                      <svg
                        width="21"
                        height="21"
                        viewBox="0 0 21 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.875 10.5C0.875 10.5 4.375 3.5 10.5 3.5C16.625 3.5 20.125 10.5 20.125 10.5C20.125 10.5 16.625 17.5 10.5 17.5C4.375 17.5 0.875 10.5 0.875 10.5Z"
                          stroke="#FFCC47"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M10.5 13.125C11.9497 13.125 13.125 11.9497 13.125 10.5C13.125 9.05025 11.9497 7.875 10.5 7.875C9.05025 7.875 7.875 9.05025 7.875 10.5C7.875 11.9497 9.05025 13.125 10.5 13.125Z"
                          stroke="#FFCC47"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </a>
                  </template>
                  <v-alert slot="no-results" :value="true" color="error">
                    Ваш поиск по запросу "{{ filters.search }}" не дал
                    результатов.
                  </v-alert>
                </v-data-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from '../../boot/axios'
import Multiselect from 'vue-multiselect'

export default {
  name: 'Account',
  components: {
    Multiselect,
  },
  data() {
    return {
      applications: null,
      user: null,

      orderList: [
        {
          status: 'all',
          name: 'Все',
        },
        {
          status: 'sent-by',
          name: 'Поступившие',
        },
        {
          status: 'registered',
          name: 'Зарегистрированные',
        },
        {
          status: 'resend',
          name: 'Возвращенные',
        },
        {
          status: 'denied',
          name: 'Отказанные',
        },
        {
          status: 'in-work',
          name: 'В работе',
        },
        {
          status: 'approved',
          name: 'Одобренные',
        },
        {
          status: 'to-be-agreed',
          name: 'На согласовании',
        },
        {
          status: 'paid',
          name: 'Выплачено',
        },
      ],
      Categories: 'all',
      filterCategories: {
        status: 'all',
        name: 'Все',
      },
      filters: {
        search: '',
      },
      page: 0,
      totalPassengers: 0,
      loading: false,
      options: {
        itemsPerPage: 5,
        page: 1,
      },
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'ФИО туриста/пассажира', value: 'name' },
        { text: 'ВИД заявления', value: 'type', width: '200px' },
        { text: 'Дата', value: 'date' },
        { text: 'Время', value: 'time' },
        { text: 'Статус заявления', value: 'status.name' },
        { text: 'Действие', value: 'action', align: 'center', sortable: false },
      ],
    }
  },
  computed: {
    filteredlist() {
      // return this.itemsList.filter((d) => {
      return this.applications.data.filter((d) => {
        if (
          d.name.toLowerCase().includes(this.filters.search.toLowerCase()) &&
          (this.filterCategories.status == 'all' ||
            d.status == this.filterCategories.status) &&
          (this.Categories == 'all' || d.category_id == this.Categories)
        ) {
          return true
        }
      })
    },
  },
  methods: {
    categoriesTab(category) {
      this.Categories = category
    },
    filterTab(category) {
      this.filterCategories = category
    },
  },
  beforeCreate() {
    if (!localStorage.token) {
      this.$router.push('/ru/login')
    }

    api.get('user').then((response) => {
      if (response.data) {
        this.user = response.data
      }
    })

    api.get('applications').then((response) => {
      // console.log(response);
      if (response.data) {
        this.applications = response.data
      }
    })
  },
}
</script>

<style scoped></style>
